import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import RuptureFormDialog from './RuptureFormDialog';


export default function Rupture({ setEtatContrat, itemState, choices }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant='contained' fullWidth>
        Rupture contrat
      </Button>
      <RuptureFormDialog
        openState={[open, setOpen]}
        setEtatContrat={setEtatContrat}
        itemState={itemState}
        choices={choices}
      />
    </>
  );
}

Rupture.propTypes = {
  setEtatContrat: PropTypes.func.isRequired,
  itemState: PropTypes.array.isRequired,
  choices: PropTypes.object,
};
