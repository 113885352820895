import React, { useEffect, useState } from 'react';

import deepmerge from 'deepmerge';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useGoBackHistory from '@/hooks/useGoBackHistory';
import useIsDifferentAddress from '@/hooks/useIsDifferentAddress';
import useSetFormValues from '@/hooks/useSetFormValues';

import InformationsGeneralesTab from '@/features/nouveau-contact/InformationsGeneralesTab';
import ProprieteTab from '@/features/nouveau-contact/ProprieteTab';
import RelationNeosylvaTab from '@/features/nouveau-contact/RelationNeosylvaTab';
import SubmitHandlerButton from '@/features/shared/SubmitHandlerButton';

import { setIsLoading } from '@/slices/isLoadingSlice';
import { fetchItemById } from '@/slices/itemSlice';
import { setMessage } from '@/slices/messageSlice';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';

export default function NouveauContactForm() {
  const goBackHistory = useGoBackHistory();

  const navigate = useNavigate();

  const choices = useChoices('nouveau_contact');

  const [createPropriete, setCreatePropriete] = useState(true);
  const [propriete, setPropriete] = useState(null);
  const parcellesList = useSelector(
    (store) =>
      store.itemReducer.filteredItems
        .parcelles_cadastrales_pressenties_sous_contrat
  );

  const dispatch = useDispatch();

  const form1 = useForm({
    defaultValues: {
      civilite: null,
      nom: null,
      activites_list: [],
      prenom: null,
      date_naissance: null,
      telephone_fixe: null,
      telephone_portable: null,
      email: null,
      adresse: null,
      commune: null,
      code_postal: null,
      departement: null,
      pays: null,
      entreprises_list: [],
      profession: null,
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    control: control1,
    getValues: getValues1,
    formState: { isDirty: isDirty1, isValid: isValid1 },
  } = form1;

  const form2 = useForm({
    defaultValues: {
      propriete: {
        nom_commun: null,
        adresse: null,
        commune: null,
        code_postal: null,
        departement: null,
        different_address: false,
        surface_totale_propriete: null,
        existence_dgd: false,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });
  const {
    getValues: getValues2,
    setValue: setValue2,
    formState: { isValid: isValid2 },
  } = form2;

  const form3 = useForm({
    defaultValues: {
      contact_neosylva: null,
      commentaire: null,
      voeux: null,
      rendez_vous_annuel_pressenti: true,
      relation_contractuelle_neosylva: false,
      newsletter: true,
      origine_commerciale_proprietaire: null,
      propriete: {
        cmf_neosylva: null,
      },
    },
    reValidateMode: 'onBlur',
    mode: 'onChange',
    values: { propriete },
  });
  const {
    getValues: getValues3,
    formState: { isDirty: isDirty3, isValid: isValid3 },
    setValue: setValue3,
  } = form3;

  const setFormValues = useSetFormValues();

  const proprietaireAdresseWatch = useWatch({
    control: control1,
    name: 'adresse',
  });

  const proprietaireCodePostalWatch = useWatch({
    control: control1,
    name: 'code_postal',
  });

  const proprietaireCommuneWatch = useWatch({
    control: control1,
    name: 'commune',
  });

  const proprieteFields = [
    ['nom_commun', false, setValue2],
    ['adresse', false, setValue2],
    ['commune', false, setValue2],
    ['code_postal', false, setValue2],
    ['departement', false, setValue2],
    ['surface_totale_propriete', false, setValue2],
    ['existence_dgd', false, setValue2],
    ['cmf_neosylva', true, setValue3],
  ];

  useEffect(() => {
    if (propriete?.id) {
      dispatch(
        fetchItemById({ itemId: propriete.id, itemType: 'proprietes' })
      ).then(({ payload }) => {
        setPropriete(payload);
        setCreatePropriete(false);
        setFormValues(proprieteFields, payload, 'propriete');
      });
    } else {
      setCreatePropriete(true);
    }
  }, [propriete?.id]);

  const addressesFields = [
    [proprietaireAdresseWatch, propriete?.adresse],
    [proprietaireCodePostalWatch, propriete?.code_postal],
    [proprietaireCommuneWatch, propriete?.commune],
  ];

  useIsDifferentAddress(
    addressesFields,
    [setValue2, 'propriete.different_address'],
    !createPropriete
  );

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [isProp, setIsProp] = useState(false);

  const onCancel = () => {
    goBackHistory({ defaultPath: '/' });
  };

  const ActionBtn = () => {
    const isLastTab = isProp ? tabValue === 2 : tabValue === 1;

    if (isLastTab) {
      let disabledSubmit;
      if (isProp) {
        disabledSubmit =
          !isDirty1 ||
          !isValid1 ||
          !isValid2 ||
          !propriete ||
          !isDirty3 ||
          !isValid3;
      } else {
        disabledSubmit = !isDirty1 || !isValid1 || !isDirty3 || !isValid3;
      }

      const onSubmit = async () => {
        const formValuesList = [getValues1(), getValues2(), getValues3()];
        const formValues = deepmerge.all(formValuesList);
        if (isProp) {
          formValues.propriete.id = propriete?.id;
        }
        formValues.parcelles_cadastrales_pressenties_sous_contrat_list =
          parcellesList;

        const res = await APIService.post({
          url: 'nouveau_contact',
          data: formValues,
          onError: (res) => {
            const message = res.data.message || res.data.detail;
            if (message) {
              dispatch(setMessage(message));
            }
          },
        }).finally(() => dispatch(setIsLoading(false)));

        const resData = res?.data;
        if (!resData) {
          return;
        }

        const message = resData?.message;
        if (message) {
          dispatch(setMessage(message));
        }

        navigate('/');
      };

      return (
        <SubmitHandlerButton
          onSubmit={onSubmit}
          label='Créer'
          disabledSubmit={disabledSubmit}
          sx={{ mt: 3, mb: 1 }}
          variant='contained'
        />
      );
    }

    let disableNext;

    if (tabValue === 0) {
      disableNext = !isDirty1 || !isValid1;
    } else {
      disableNext = !isValid2 || !propriete;
    }

    return (
      <Button
        variant='contained'
        sx={{ mt: 3, mb: 1 }}
        disabled={disableNext}
        onClick={() => setTabValue(tabValue + 1)}
      >
        Suivant
      </Button>
    );
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        Nouveau contact
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant='scrollable'
        allowScrollButtonsMobile
      >
        <Tab label='Informations générales' />
        {isProp && <Tab label='Propriété' />}
        <Tab label='Relation Néosylva' />
      </Tabs>
      <InformationsGeneralesTab
        form={form1}
        tabValue={tabValue}
        choices={choices}
        isPropState={{ isProp, setIsProp }}
      />
      {isProp && (
        <ProprieteTab
          form={form2}
          tabValue={tabValue}
          choices={choices}
          parcellesList={parcellesList}
          proprieteState={{ propriete, setPropriete }}
          createPropriete={createPropriete}
        />
      )}
      <RelationNeosylvaTab
        form={form3}
        tabValue={tabValue}
        choices={choices}
        isProp={isProp}
        createPropriete={createPropriete}
      />
      <Button
        variant='outlined'
        sx={{ mt: 3, mb: 1, mr: 1 }}
        onClick={onCancel}
      >
        Annuler
      </Button>
      <ActionBtn />
    </Box>
  );
}
