import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ConstatsReprisesEssences() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'constats_reprises_essences';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/constat-reprise-essence/${itemId}`);
  };

  const handleDeleteItem = async (itemId, dateReprise, tauxReprise) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression du constat ${fromISODate(
        dateReprise
      )} - ${tauxReprise}?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() =>
            handleDeleteItem(
              params.id,
              params.row.date_reprise,
              params.row.taux_reprise
            )
          }
        />,
      ],
    },
    {
      field: 'date_reprise',
      headerName: 'Date de reprise',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODate(value),
    },
    {
      field: 'taux_reprise',
      headerName: 'Taux de reprise',
      width: 140,
      type: 'number',
      editable: false,
    },
    {
      field: 'hauteur_moyenne',
      headerName: 'Hauteur moyenne',
      width: 140,
      type: 'number',
      editable: false,
    },
    {
      field: 'observations_reprise',
      headerName: 'Observations',
      width: 220,
      editable: false,
    },
    {
      field: 'essence_parcelle',
      headerName: 'Essence de parcelle',
      width: 260,
      valueGetter: (value) =>
        `${value.essence.nom_vernaculaire} (${value.type.nom})`, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/essence-parcelle/${row.essence_parcelle.id}`}
        >
          {row.essence_parcelle.essence.nom_vernaculaire} (
          {row.essence_parcelle.type.nom})
        </Link>
      ),
    },
    {
      field: 'unite_gestion',
      headerName: 'Unité de gestion',
      width: 260,
      valueGetter: (_, row) => row.essence_parcelle.unite_gestion.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/unite-gestion/${row.essence_parcelle.unite_gestion.id}`}
        >
          {row.essence_parcelle.unite_gestion.nom}
        </Link>
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (_, row) =>
        row.essence_parcelle.unite_gestion.propriete.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/propriete/${row.essence_parcelle.unite_gestion.propriete.id}`}
        >
          {row.essence_parcelle.unite_gestion.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/constat-reprise-essence');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        {"Constats de reprises d'essences"}
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            {"Ajouter un constat de reprise d'essence"}
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
