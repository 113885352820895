import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import BaseDataGrid from '@/components/BaseDataGrid';

export default function CpcTable({
  unitesGestionList,
  parcellesCadastralesSousContratList,
  datagridSx,
}) {
  const columns = useMemo(() => {
    if (!Array.isArray(unitesGestionList)) {
      return [];
    }

    const cols = [
      {
        field: 'cpc',
        headerName: 'Unités de gestion / Parcelles cadastrales',
        width: 240,
        editable: false,
        cellClassName: 'datagrid-cell',
        renderHeader: () => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div className='MuiDataGrid-columnHeaderTitle'>
                Unités de gestion
              </div>
              <ArrowForwardIcon fontSize='small' />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div className='MuiDataGrid-columnHeaderTitle'>
                Parcelles cadastrales
              </div>
              <ArrowDownwardIcon fontSize='small' />
            </div>
          </div>
        ),
      },
    ];

    unitesGestionList.forEach((uniteGestion) => {
      cols.push({
        field: `ug-${uniteGestion.id}`,
        headerName: uniteGestion.nom,
        width: 100,
        editable: false,
      });
    });

    cols.push({
      field: 'surface_sig',
      headerName: 'Surface SIG intégrée au contrat (ha)',
      width: 260,
      editable: false,
    });

    return cols;
  }, [unitesGestionList]);

  const rows = useMemo(() => {
    if (
      !Array.isArray(parcellesCadastralesSousContratList) ||
      parcellesCadastralesSousContratList.length === 0
    ) {
      return [];
    }

    const rowData = parcellesCadastralesSousContratList.map(
      (parcelleCadastrale, idx) => {
        const row = {
          id: idx,
          cpc: `${parcelleCadastrale.prefixe}-${parcelleCadastrale.section}-${parcelleCadastrale.numero}`,
          surface_sig: parcelleCadastrale.surface_sig,
        };

        parcelleCadastrale.pppc_list.forEach((pppc) => {
          row[`ug-${pppc.unite_gestion.id}`] = pppc.surface_sig;
        });

        return row;
      }
    );

    const totalRow = {
      id: 'total',
      cpc: 'Surface totale des unités de gestion (ha)',
      surface_sig: parcellesCadastralesSousContratList.reduce(
        (acc, parcelle) => acc + parcelle.surface_sig,
        0
      ),
    };

    unitesGestionList.forEach((uniteGestion) => {
      totalRow[`ug-${uniteGestion.id}`] = uniteGestion.surface_sig_ug;
    });

    rowData.push(totalRow);

    return rowData;
  }, [parcellesCadastralesSousContratList, unitesGestionList]);

  return (
    <BaseDataGrid
      columns={columns}
      rows={rows}
      sx={datagridSx}
      headerClassName='datagrid-header'
      alternateRowColor
    />
  );
}

CpcTable.propTypes = {
  unitesGestionList: PropTypes.array,
  parcellesCadastralesSousContratList: PropTypes.array,
  datagridSx: PropTypes.object.isRequired,
};
