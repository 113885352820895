import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import { manyItemsFilterOperator } from '@/components/manyItemsFilterOperator';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function PeuplementInitiaux() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'peuplements_initiaux';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/peuplement-initial/${itemId}`);
  };

  const handleDeleteItem = async (itemId, ppltType) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression du peuplement ${ppltType} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const essenceRenderCell = (row, essenceType) => (
    <ManyItemsCell
      itemId={row.id}
      relatedItems={{
        relatedItemsList: row.essences_list.filter(
          (value) => value.type === essenceType
        ),
        getRelatedName: (value) => {
          let essenceRepr = `${value.essence.nom_vernaculaire}`;
          if (value.pourcentage_surface_terriere) {
            essenceRepr += ` - ${value.pourcentage_surface_terriere}%`;
          }
          return essenceRepr;
        },
        relatedVerboseName: 'essence',
      }}
    />
  );

  const essenceValueGetter = (row, essenceType) =>
    row.essences_list
      .filter((value) => value.type === essenceType)
      .map((value) => value.essence.nom_vernaculaire)
      .join(', ');

  const essenceValueOptions = choices?.peuplements_initiaux_types.map(
    (value) => value.nom
  );

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.type.nom)}
        />,
      ],
    },
    {
      field: 'type',
      headerName: 'Type de peuplement',
      type: 'singleSelect',
      valueOptions: choices?.peuplements_initiaux_types.map(
        (value) => value.nom
      ),
      width: 220,
      editable: false,
      valueGetter: (value) => value.nom,
    },
    {
      field: 'surface_terriere',
      headerName: 'Surface terrière (G en m²/ha)',
      width: 220,
      editable: false,
    },
    {
      field: 'essences_principales',
      headerName: 'Essences principales',
      type: 'singleSelect',
      valueOptions: essenceValueOptions,
      filterOperators: manyItemsFilterOperator(),
      width: 260,
      valueGetter: (_, row) => essenceValueGetter(row, 'Principale'),
      renderCell: ({ row }) => essenceRenderCell(row, 'Principale'),
    },
    {
      field: 'essences_secondaires',
      headerName: 'Essences secondaires',
      type: 'singleSelect',
      valueOptions: essenceValueOptions,
      filterOperators: manyItemsFilterOperator(),
      width: 260,
      valueGetter: (_, row) => essenceValueGetter(row, 'Secondaire'),
      renderCell: ({ row }) => essenceRenderCell(row, 'Secondaire'),
    },
    {
      field: 'qualite_etat_sanitaire',
      headerName: 'Qualité/état sanitaire',
      width: 220,
      editable: false,
    },
    {
      field: 'structure',
      headerName: 'Structure',
      type: 'singleSelect',
      valueOptions: choices?.structures.map((value) => value.code),
      width: 160,
      editable: false,
      valueGetter: (value) => value?.code,
    },
    {
      field: 'annee',
      headerName: "Année d'installation",
      width: 160,
      editable: false,
    },
    {
      field: 'valeur_eco_estimee',
      headerName: 'Valeur économique totale estimée (€)',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'observations',
      headerName: 'Observations',
      width: 220,
      editable: false,
    },
    {
      field: 'unite_gestion',
      headerName: 'Unité de gestion',
      width: 260,
      valueGetter: (value) => value.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/unite-gestion/${row.unite_gestion.id}`}
        >
          {row.unite_gestion.nom}
        </Link>
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (_, row) => row.unite_gestion.propriete.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/propriete/${row.unite_gestion.propriete.id}`}
        >
          {row.unite_gestion.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/peuplement-initial');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Peuplement initiaux
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un peuplement initial
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
