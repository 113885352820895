import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import ManyItemsCell from '@/components/ManyItemsCell';
import { manyItemsFilterOperator } from '@/components/manyItemsFilterOperator';

import TableauBordCMFGrids from '@/features/tableau-bord-cmf/TableauBordCMFGrids';

import { deleteItem } from '@/slices/itemSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

const COLOR_MAPPER = {
  NEOSYLVA: '#ce0030',
  GESTIONNAIRE: '#ff9200',
  STANDBY: '#384f5b',
  SIGNE: '#60812c',
  ABANDONNE: '#7f7f7f',
};

/**
 * Custom comparator to handle ordering between array of string, with variable length.
 * If arrays have different length, the longest array is ordered first.
 * If arrays have the same length, they are ordered alphabetically.
 * @param {Array<String>} a 
 * @param {Array<String>} b 
 * @return {Boolean} 'True' if a is greater than b, 'False' otherwise
 */
const COMPARATOR = (a, b) => {
  const codeChantierMapper = (contrat) => contrat.code_chantier;
  a = a.map(codeChantierMapper);
  b = b.map(codeChantierMapper);

  if (a.length === b.length) {
    return a.join('').toLowerCase() > b.join('').toLowerCase() ? 1 : -1;
  }

  return a.length < b.length ? 1 : -1;
};

// By default, the value is 52, : https://mui.com/x/react-data-grid/row-height/
const DEFAULT_ROW_HEIGHT = 40;

const getRowHeight = ({ model }) =>
  DEFAULT_ROW_HEIGHT * model.contrats_proprietaires_list.length ||
  DEFAULT_ROW_HEIGHT;

export default function TableauBordCMF() {
  const dispatch = useDispatch();

  const itemType = 'tableau_bord_cmf';

  const { open } = useConfirmationDialog();

  const handleDeleteItem = async (itemId, itemName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const navigate = useNavigate();
  
  const [tabValue, setTabValue] = useState(0);
  const proprietesProspection = useRef([]);
  const proprietesGestion = useRef([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const choices = useChoices(itemType);
  const items = useItems(itemType);

  useEffect(() => {
    proprietesGestion.current = [];
    proprietesProspection.current = [];

    items?.forEach((propriete) => {
      if (propriete.contrats_proprietaires_list?.length) {
        propriete.contrats_proprietaires_list.some(
          (contrat) => contrat.etat.code === 'SIGNE'
        )
          ? proprietesGestion.current.push(propriete)
          : proprietesProspection.current.push(propriete);
      }
    });
  }, [items]);

  const columns = [
    {
      field: 'delete',
      type: 'actions',
      width: 80,
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          key={id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => navigate(`/fiche-propriete/${row.id}`)}
        />,
        <GridActionsCellItem
          key={id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(id, row.nom_commun)}
          sx={{
            visibility: row.contrats_proprietaires_list?.length
              ? 'hidden'
              : 'visible',
          }}
        />,
      ],
    },
    {
      field: 'departement',
      headerName: 'Département',
      type: 'singleSelect',
      valueOptions: choices?.departements,
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.departement,
    },
    {
      field: 'nom_commun',
      headerName: 'Propriété',
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.nom_commun,
    },
    {
      field: 'proprietaire',
      headerName: 'Propriétaire(s)',
      width: 200,
      editable: false,
      valueGetter: (_, row) =>
        row.proprietaires_list.map(
          (prop) => `${prop.contact.prenom} ${prop.contact.nom}`
        ),
      renderCell: ({ row }) => {
        switch (row.proprietaires_list.length) {
          case 0:
            return null;
          case 1:
            return (
              <Button
                component={RouterLink}
                to={`/proprietaire/${row.proprietaires_list[0].id}`}
              >
                {`${row.proprietaires_list[0].contact.prenom} ${row.proprietaires_list[0].contact.nom}`}
              </Button>
            );
          default:
            return (
              <ManyItemsCell
                itemId={row.id}
                relatedItems={{
                  relatedPath: '/proprietaire',
                  relatedItemsList: row.proprietaires_list,
                  getRelatedName: (proprietaire) =>
                    `${proprietaire.contact.prenom} ${proprietaire.contact.nom}`,
                  relatedVerboseName: 'Propriétaire',
                }}
              />
            );
        }
      },
      sortComparator: COMPARATOR,
    },
    {
      field: 'gestionnaire',
      headerName: 'Gestionnaire',
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.gestionnaire?.contact.nom,
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/gestionnaire-pro/${row.gestionnaire?.id}`}
        >
          {row.gestionnaire?.contact.nom}
        </Link>
      ),
    },
    {
      field: 'commune',
      headerName: 'Commune',
      width: 200,
      editable: false,
      valueGetter: (_, row) => row.commune,
    },
    {
      field: 'lien',
      headerName: 'Lien existant entre ERP et Sylvalibre',
      width: 100,
      editable: false,
      valueGetter: () => null,
    },
    {
      field: 'surface_sous_contrat',
      headerName: 'Surface sous contrat (ha)',
      width: 100,
      editable: false,
      valueGetter: (_, row) =>
        row.contrats_proprietaires_list.reduce(
          (total_surface, contrat) =>
            total_surface + contrat.surface_pressentie_sous_contrat,
          0
        ),
    },
    {
      field: 'contrats',
      headerName: 'Contrats',
      type: 'singleSelect',
      valueOptions: choices?.etat.map(etat => ({ value: etat.code, label: etat.nom })),
      filterOperators: manyItemsFilterOperator((contrat) => contrat.etat.code),
      width: 350,
      editable: false,
      valueGetter: (_, row) => row.contrats_proprietaires_list,
      renderCell: ({ row }) => (
        <Grid container>
          {row.contrats_proprietaires_list?.map((contrat) => (
            <Tooltip
              key={contrat.id}
              title={contrat.etat.nom}
              placement='bottom'
              enterDelay={500}
              disableInteractive
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -22],
                      },
                    },
                  ],
                },
              }}
            >
              <Button
                sx={{
                  display: 'flex',
                  color: COLOR_MAPPER[contrat.etat.code] || 'black',
                  justifyContent: 'start',
                  height: DEFAULT_ROW_HEIGHT,
                }}
                fullWidth
                onClick={() => navigate(`/fiche-contrat/${contrat.id}?propriete_id=${row.id}`)}
              >
                {contrat.code_chantier}
              </Button>
            </Tooltip>
          ))}
        </Grid>
      ),
      sortComparator: COMPARATOR,
    },
    {
      field: 'add',
      type: 'actions',
      width: 80,
      getActions: ({ id, row }) => [
        <GridActionsCellItem
          key={id}
          icon={
            <Tooltip title='Ajouter un nouveau contrat'>
              <LibraryAddIcon />
            </Tooltip>
          }
          label='Ajouter'
          onClick={() => navigate(`/fiche-contrat?propriete_id=${row.id}`)}
        />,
      ],
    },
  ];

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={2} variant='h5' component='div'>
        Tableau de bord CMF
      </Typography>
      <TableauBordCMFGrids
        tabValue={tabValue}
        choices={choices}
        handleTabChange={handleTabChange}
        columns={columns}
        proprietesGestion={proprietesGestion.current}
        proprietesProspection={proprietesProspection.current}
        items={items}
        getRowHeight={getRowHeight}
      />
    </Box>
  );
}
