import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { useGridApiRef } from '@mui/x-data-grid';

import CustomGridToolBar from './CustomGridToolBar';
import useOnMount from '@/hooks/useOnMount';
import {
  getDefaultModels,
  setDatagridDefaultModels,
  setModel,
} from '@/slices/datagridSlice';
import BaseDataGrid from './BaseDataGrid';

export default function StatefulDataGrid({
  defaultPageSize = 10,
  boxSx,
  ...props
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const username = useSelector(
    (store) => store.persistAuthReducer.user?.username
  );
  const key = `${username}${location.pathname}`;
  const datagridModels = useSelector(
    (store) => store.persistDatagridReducer[key]
  );

  const [hasModel, setHasModel] = useState(false);

  useEffect(() => {
    const hasSortModel = datagridModels?.sorting.length > 0;
    const hasFilterModel =
      datagridModels?.filter.quickFilterValues?.some((value) => value !== '') ||
      datagridModels?.filter.items.some((item) => item.value);
    const hasVisibilityModel =
      datagridModels &&
      Object.values(datagridModels.visibility).some((value) => !value);

    setHasModel(hasSortModel || hasFilterModel || hasVisibilityModel);
  }, [datagridModels]);

  const apiRef = useGridApiRef();

  const defaultPaginationModel = {
    page: 0,
    pageSize: defaultPageSize,
  };

  const setModels = useCallback(
    (models) => {
      apiRef.current.setFilterModel(models.filter);
      apiRef.current.setSortModel(models.sorting);
      apiRef.current.setColumnVisibilityModel(models.visibility);
      apiRef.current.setPaginationModel(models.pagination);
    },
    [apiRef]
  );

  useOnMount(() => {
    if (datagridModels) {
      setModels(datagridModels);
    } else {
      dispatch(setDatagridDefaultModels({ key, defaultPaginationModel }));
      apiRef.current.setPaginationModel(defaultPaginationModel);
    }
  });

  const onReset = useCallback(() => {
    dispatch(setDatagridDefaultModels({ key, defaultPaginationModel }));
    const defaultModel = getDefaultModels(defaultPaginationModel);
    setModels(defaultModel);
  }, [dispatch, key, defaultPaginationModel, setModels]);

  const handleSaveState = useCallback(
    (stateType, state) => {
      dispatch(
        setModel({
          stateType: stateType,
          newState: state,
          key: key,
        })
      );
    },
    [dispatch, key]
  );

  return (
    <BaseDataGrid
      boxProps={{
        component: Paper,
        elevation: 3,
        sx: {
          ...boxSx,
          width: '100%',
          flex: '1 1 0px',
          overflow: 'auto',
          height: '100%',
        },
      }}
      autoHeight={false}
      apiRef={apiRef}
      slots={{
        toolbar: CustomGridToolBar,
        loadingOverlay: LinearProgress,
      }}
      slotProps={{
        toolbar: { hasModel, onReset },
      }}
      onPaginationModelChange={(value) => {
        handleSaveState('pagination', value);
      }}
      onSortModelChange={(value) => {
        handleSaveState('sorting', value);
      }}
      onColumnVisibilityModelChange={(value) => {
        handleSaveState('visibility', value);
      }}
      onFilterModelChange={(value) => {
        handleSaveState('filter', value);
      }}
      {...props}
    />
  );
}

StatefulDataGrid.propTypes = {
  defaultPageSize: PropTypes.number,
  boxSx: PropTypes.object,
};
