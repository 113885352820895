import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function DocumentsGestionDurable() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'dgd';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/document-gestion-durable/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.type.code)}
        />,
      ],
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: choices?.types.map((value) => value.code),
      width: 220,
      editable: false,
      valueGetter: (value) => value.code,
    },
    {
      field: 'surface_totale',
      headerName: 'Surface totale (ha)',
      width: 220,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'numero',
      headerName: 'Numéro',
      width: 180,
      editable: false,
    },
    {
      field: 'date_debut',
      headerName: 'Date de début',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODate(value),
    },
    {
      field: 'date_fin',
      headerName: 'Date de fin',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODate(value),
    },
    {
      field: 'proprietes_list',
      headerName: 'Propriétés',
      width: 260,
      valueGetter: (value) =>
        value.map((propriete) => propriete.nom_commun),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.proprietes_list,
            getRelatedName: (propriete) => propriete.nom_commun,
            relatedPath: '/propriete',
            relatedVerboseName: 'propriété',
          }}
        />
      ),
    },
    {
      field: 'interventions_passees_list',
      headerName: 'Interventions passées',
      width: 260,
      valueGetter: (value) =>
        value.map(
          (intervention) =>
            `${intervention.type.code} - ${intervention.annee_prevue}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.interventions_passees_list,
            getRelatedName: (intervention) =>
              `${intervention.type.code} - ${intervention.annee_prevue}`,
            relatedPath: '/intervention-passee',
            relatedVerboseName: 'intervention',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/document-gestion-durable');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        DGD
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un DGD
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
