import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import SignatureContratFormDialog from './SignatureContratFormDialog';

export default function SignatureContrat({
  setEtatContrat,
  codesChantierList,
  itemState,
  documentsList,
  unitesGestionList,
}) {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant='contained'
        fullWidth
      >
        Signature contrat sous seing privé
      </Button>
      <SignatureContratFormDialog
        openState={[open, setOpen]}
        setEtatContrat={setEtatContrat}
        codesChantierList={codesChantierList}
        itemState={itemState}
        documentsList={documentsList}
        unitesGestionList={unitesGestionList}
      />
    </>
  );
}

SignatureContrat.propTypes = {
  setEtatContrat: PropTypes.func.isRequired,
  codesChantierList: PropTypes.array.isRequired,
  itemState: PropTypes.array.isRequired,
  documentsList: PropTypes.array,
  unitesGestionList: PropTypes.array,
};