import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function Interventions() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'interventions';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/intervention/${itemId}`);
  };

  const handleDeleteItem = async (
    itemId,
    interventionType,
    interventionAnneePrevue
  ) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de l'intervention ${interventionType} prévue en ${interventionAnneePrevue} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() =>
            handleDeleteItem(
              params.id,
              params.row.type.code,
              params.row.annee_prevue
            )
          }
        />,
      ],
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: choices?.types.map(
        (value) => `(${value.code}) ${value.nom}`
      ),
      width: 220,
      editable: false,
      valueGetter: (value) => `(${value.code}) ${value.nom}`,
    },
    {
      field: 'etat',
      headerName: 'État',
      type: 'singleSelect',
      valueOptions: choices?.etats.map((value) => value.nom),
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'annee_prevue',
      headerName: 'Année prévue',
      width: 220,
      editable: false,
    },
    {
      field: 'date_effective',
      headerName: 'Date effective',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODate(value),
    },
    {
      field: 'commentaire',
      headerName: 'Commentaire',
      width: 320,
      editable: false,
    },
    {
      field: 'quantite_commercialisee_m3_stere',
      headerName: 'Quantité',
      width: 220,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(2) : null),
    },
    {
      field: 'qualite_commercialisee',
      headerName: 'Qualité commercialisée',
      width: 220,
      editable: false,
    },
    {
      field: 'surface',
      headerName: 'Surface (ha)',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'unite_gestion',
      headerName: 'Unité de gestion',
      width: 260,
      valueGetter: (value) => value.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/unite-gestion/${row.unite_gestion.id}`}
        >
          {row.unite_gestion.nom}
        </Link>
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (_, row) => row.unite_gestion.propriete.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/propriete/${row.unite_gestion.propriete.id}`}
        >
          {row.unite_gestion.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'contrat_etf',
      headerName: 'Contrat ETF',
      width: 260,
      valueGetter: (value) => value?.code, // For filtering ...
      renderCell: ({ row }) =>
        row.contrat_etf && (
          <Link
            component={RouterLink}
            to={`/contrat-etf/${row.contrat_etf.id}`}
          >
            {row.contrat_etf.code}
          </Link>
        ),
    },
    {
      field: 'depenses_list',
      headerName: 'Dépenses',
      width: 260,
      valueGetter: (value) =>
        value?.map(
          (depense) =>
            `${fromISODate(depense.date_previsionnelle_paiement)} - ${
              depense.montant_previsionnel
            }`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.depenses_list ? row.depenses_list : [],
            getRelatedName: (depense) =>
              `${fromISODate(depense.date_previsionnelle_paiement)} - ${
                depense.montant_previsionnel
              }`,
            relatedVerboseName: 'dépense',
            relatedPath: '/depense',
          }}
        />
      ),
    },
    {
      field: 'recettes_list',
      headerName: 'Recettes',
      width: 260,
      valueGetter: (value) =>
        value?.map(
          (recette) =>
            `${fromISODate(recette.date_previsionnelle_paiement)} - ${
              recette.montant_previsionnel
            }`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.recettes_list ? row.recettes_list : [],
            getRelatedName: (recette) =>
              `${fromISODate(recette.date_previsionnelle_paiement)} - ${
                recette.montant_previsionnel
              }`,
            relatedVerboseName: 'recette',
            relatedPath: '/recette',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/intervention');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Interventions
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une intervention
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
