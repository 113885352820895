import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function StocksCarbone() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'stocks_carbone';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/stock-carbone/${itemId}`);
  };

  const handleDeleteItem = async (itemId, nomProjet) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression du stock carbone ${nomProjet}?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom_projet)}
        />,
      ],
    },
    {
      field: 'nom_projet',
      headerName: 'Nom du projet',
      width: 220,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom_projet} />,
    },
    {
      field: 'surface_eligible',
      headerName: 'Surface éligible (ha)',
      width: 160,
      type: 'number',
      editable: false,
      valueFormatter: (value) => value.toFixed(4),
    },
    {
      field: 'methodologie_carbone',
      headerName: 'Méthodologie carbone',
      type: 'singleSelect',
      valueOptions: choices?.methodologies_types.map(value => value.nom),
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'rea_total',
      headerName: 'REA total',
      width: 160,
      type: 'number',
      editable: false,
    },
    {
      field: 'rea_foret',
      headerName: 'REA forêt',
      width: 160,
      type: 'number',
      editable: false,
    },
    {
      field: 'rea_produit',
      headerName: 'REA produit',
      width: 160,
      type: 'number',
      editable: false,
    },
    {
      field: 'rea_substitution',
      headerName: 'REA substitution',
      width: 160,
      type: 'number',
      editable: false,
    },
    {
      field: 'statut',
      headerName: 'Statut',
      width: 220,
      editable: false,
    },
    {
      field: 'date_notification_lbc',
      headerName: 'Date notification LBC',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_labellisation_lbc',
      headerName: 'Date labellisation LBC',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'date_verification_lbc',
      headerName: 'Date vérification LBC',
      width: 220,
      editable: false,
      valueFormatter: (value) => (fromISODate(value)),
    },
    {
      field: 'num_demarche_simplifiee',
      headerName: 'Numéro de démarche simplifiée',
      width: 160,
      editable: false,
    },
    {
      field: 'commentaire',
      headerName: 'Commentaire',
      width: 320,
      editable: false,
    },
    {
      field: 'unite_gestion',
      headerName: 'Unité de gestion',
      width: 260,
      valueGetter: (value) => value.nom, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/unite-gestion/${row.unite_gestion.id}`}
        >
          {row.unite_gestion.nom}
        </Link>
      ),
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (_, row) => row.unite_gestion.propriete.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/propriete/${row.unite_gestion.propriete.id}`}
        >
          {row.unite_gestion.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/stock-carbone');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Stocks carbone
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un stock carbone
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
