import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useChoices from '@/hooks/useChoices';
import useFetchFilteredItems from '@/hooks/useFetchFilteredItems';
import useFormItem from '@/hooks/useFormItem';
import useOnMount from '@/hooks/useOnMount';

import AutocompleteAPI from '@/components/AutoCompleteAPI';
import SelectField from '@/components/controlled-fields/SelectField';


import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function UniteGestionForm({ item }) {
  const itemType = 'unites_gestion';
  const itemPath = '/unites-gestion';

  const fetchFilteredItems = useFetchFilteredItems();

  const choices = useChoices(itemType);

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const contratsProprietaires = useSelector(
    (store) => store.itemReducer.filteredItems.contrats_proprietaires
  );

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    setValue,
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const proprieteWatch = useWatch({
    control,
    name: 'propriete',
  });

  const contratProprietaireWatch = useWatch({
    control,
    name: 'contrat_proprietaire',
  });

  useEffect(() => {
    if (!proprieteWatch) {
      return;
    }

    fetchFilteredItems({
      filter: { type: 'propriete', type_id: proprieteWatch.id },
      itemTypes: ['contrats_proprietaires'],
    });
  }, [proprieteWatch]);

  const onClearStation = () => {
    setValue('station', null, { shouldValidate: true });
  };

  useOnMount(() => {
    if (item.id) {
      fetchFilteredItems({
        filter: { propriete_id: item.propriete.id },
        itemTypes: ['stations'],
      });
    }
  });

  const stationsList = useSelector(
    (store) => store.itemReducer.filteredItems.stations
  );
  const onClearTypeTraitement = () => {
    setValue('type_traitement', null, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} unité de gestion
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'propriete'}
              control={control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  disabled={!!item.id}
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  defaultValue={item.propriete}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'contrat_proprietaire'}
              control={control}
              rules={{ required: 'Contrat propriétaire requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  required={true}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  onChange={(_, value) => onChange(value)}
                  disabled={!!item.id || !proprieteWatch}
                  error={error}
                  value={value}
                  getOptionLabel={(item) => item?.code_chantier || ''}
                  options={contratsProprietaires || []}
                  defaultValue={item.contrat_proprietaire}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        value:
                          params.inputProps.value === '' && value
                            ? value.code_chantier
                            : params.inputProps.value,
                      }}
                      label='Contrat propriétaire *'
                      error={!!error}
                      value={value || ''}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'nom'}
              control={control}
              rules={{ maxLength: 80, required: 'Nom requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Nom'}
                  inputProps={{ maxLength: 80 }}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'peuplement_objectif'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='type-pplt-objectif-select-label'>
                    Peuplement objectif
                  </InputLabel>
                  <Select
                    labelId='type-pplt-objectif-select-label'
                    id='type-pplt-objectif-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Peuplement objectif'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.peuplements_objectifs_types.map((type_peuplement) => (
                        <MenuItem
                          key={type_peuplement.code}
                          value={type_peuplement}
                        >
                          {type_peuplement.nom}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SelectField
              control={control}
              name='type_traitement'
              label='Type de traitement'
              renderValue={(value) => value.nom}
              values={choices?.types_traitements}
              itemMapper={(type) => ({
                key: type.id,
                label: type.nom,
              })}
              onClear={onClearTypeTraitement}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'surface_sig_ug'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,4})?$/,
                  message: 'Surface unité de gestion invalide.',
                },
                required: 'Surface unité de gestion  requise.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Surface unité de gestion (ha)'}
                  type={'number'}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(4);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <SelectField
              control={control}
              name='station'
              label='Station correspondante'
              values={stationsList}
              renderValue={(station) => station?.numero}
              itemMapper={(station) => ({
                key: station?.temp_uuid,
                label: station?.numero,
              })}
              onClear={onClearStation}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'foret_semi_naturelle'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel id='semi-naturelle-select-label'>
                    Forêt semi naturelle
                  </InputLabel>
                  <Select
                    labelId='semi-naturelle-select-label'
                    id='semi-naturelle-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Forêt semi naturelle'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.forets_semi_naturelles.map(
                        (foret_semi_naturelle) => (
                          <MenuItem
                            key={foret_semi_naturelle.id}
                            value={foret_semi_naturelle}
                          >
                            {foret_semi_naturelle.nom}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'densite'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+$/,
                  message: 'Densité invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(0);
                    }
                    onChange(val);
                  }}
                  value={value ? value : ''}
                  label={'Densité'}
                  type={'number'}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              name={'eligibilite_carbone'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label='Éligibilité carbone'
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'itineraire_sylvicole_preconise'}
              control={control}
              rules={{ required: 'Itineraire sylvicole préconisé requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required={true}>
                  <InputLabel id='itineraire-sylvicole-select-label'>
                    Itineraire sylvicole préconisé
                  </InputLabel>
                  <Select
                    labelId='itineraire-sylvicole-select-label'
                    id='itineraire-sylvicole-select'
                    onChange={onChange}
                    value={value || ''}
                    label='Itineraire sylvicole préconisé'
                    error={!!error}
                    renderValue={(value) => value.nom}
                  >
                    {choices &&
                      choices.itineraires_sylvicoles_preconises.map(
                        (itineraire_sylvicole_preconise) => (
                          <MenuItem
                            key={itineraire_sylvicole_preconise.id}
                            value={itineraire_sylvicole_preconise}
                          >
                            {itineraire_sylvicole_preconise.nom}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name={'observations_peuplement'}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Observations'}
                  fullWidth
                  error={!!error}
                  multiline
                  minRows={3}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid || !contratProprietaireWatch}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

UniteGestionForm.propTypes = {
  item: PropTypes.object.isRequired,
};
