import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import useFormItem from '@/hooks/useFormItem';

import AutocompleteAPI from '@/components/AutoCompleteAPI';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import APIService from '@/APIService';
import FormSubmitButtons from '@/features/shared/FormSubmitButtons';

export default function ContratFinanceurForm({ item }) {
  const itemType = 'contrats_financeur';
  const itemPath = '/contrats-financeur';

  const { onCancel, onSubmit } = useFormItem(item.id, itemType, itemPath);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: item,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const uniteGestionForm = useForm({
    defaultValues: { propriete: null, parcelle: null },
  });
  const [propriete, setPropriete] = useState(null);
  const [parcelle, setParcelle] = useState(null);

  return (
    <Box elevation={3} component={Paper} sx={{ width: '100%', p: 3 }}>
      <Typography mb={2} variant='h5' component='div'>
        {item.id ? 'Édition' : 'Création'} contrat financeur
      </Typography>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'code'}
              control={control}
              rules={{ required: 'Code requis.', maxLength: 14 }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Code chantier'}
                  inputProps={{ maxLength: 14 }}
                  required={true}
                  fullWidth
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'financeur'}
              control={control}
              rules={{ required: 'Financeur requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  onChange={onChange}
                  error={error}
                  value={value}
                  getItemDescription={(item) => {
                    let description = item.contact.nom;
                    if (item.contact.prenom) {
                      description = `${item.contact.prenom} ${description}`;
                    }
                    return description;
                  }}
                  label={'Financeur'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'financeurs_carbone/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  required={true}
                  defaultValue={item.financeur}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'propriete'}
              control={uniteGestionForm.control}
              rules={{ required: 'Propriété requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  getItemDescription={(item) => item.nom_commun || ''}
                  label={'Propriété'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: 'proprietes/search/',
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  onChange={(val) => {
                    setPropriete(val);
                    return onChange(val);
                  }}
                  error={error}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'parcelle'}
              control={uniteGestionForm.control}
              rules={{ required: 'Unité de gestion requise.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  disabled={!propriete}
                  getItemDescription={(item) => item.nom || ''}
                  label={'Unité de gestion'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: `unites_gestion/${propriete.id}/search/`,
                      opts: { params: { name: searchTerm } },
                    })
                  }
                  onChange={(val) => {
                    setParcelle(val);
                    return onChange(val);
                  }}
                  error={error}
                  value={value}
                  required={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'stock_carbone'}
              control={control}
              rules={{ required: 'Stock carbone requis.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <AutocompleteAPI
                  required={true}
                  disabled={!parcelle}
                  getItemDescription={(item) => item.nom_projet || ''}
                  label={'Stock carbone'}
                  searchFunc={(searchTerm) =>
                    APIService.get({
                      url: `stocks_carbone/${parcelle.id}/search/`,
                      opts: {
                        params: {
                          name: searchTerm,
                        },
                      },
                    })
                  }
                  multiple={false}
                  onChange={onChange}
                  error={error}
                  value={value}
                  defaultValue={item.stock_carbone}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'stock_vendu'}
              control={control}
              rules={{
                required: 'Stock vendu requis.',
                pattern: {
                  value: /^\d+$/,
                  message: 'Stock vendu invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Stock vendu'}
                  type={'number'}
                  fullWidth
                  required={true}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(0);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'prix_vente'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Prix de vente invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Prix de vente'}
                  type={'number'}
                  fullWidth
                  required={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name={'montant'}
              control={control}
              rules={{
                pattern: {
                  value: /^\d+(\.\d{0,2})?$/,
                  message: 'Montant invalide.',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value ? value : ''}
                  label={'Montant'}
                  type={'number'}
                  fullWidth
                  required={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  onBlur={(e) => {
                    let val = parseFloat(e.target.value);
                    if (val) {
                      val = val.toFixed(2);
                    }
                    onChange(val);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <FormSubmitButtons
          onSubmit={handleSubmit(onSubmit)}
          submitLabel={item.id ? 'Éditer' : 'Créer'}
          disabledSubmit={!isDirty || !isValid}
          onCancel={onCancel}
        />
      </form>
    </Box>
  );
}

ContratFinanceurForm.propTypes = {
  item: PropTypes.object.isRequired,
};
