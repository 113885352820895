import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import TabPanelForm from '@/components/TabPanelForm';
import BaseDataGrid from '@/components/BaseDataGrid';
import { setFilteredItems } from '@/slices/itemSlice';
import { fromISODateTime } from '@/utils/date';

export default function ContratsNeosylvaTab({
  tabValue,
  contratsProprietaireList,
  onSubmit,
  disabledSubmit,
}) {
  const navigate = useNavigate();
  const { open } = useConfirmationDialog();
  const dispatch = useDispatch();

  const surfaceTotale = useMemo(() => {
    if (!contratsProprietaireList) {
      return;
    }

    const surfaceTotale = contratsProprietaireList
      .filter((contrat) => contrat.etat.code === 'SIGNE')
      .reduce(
        (surfaceTotale, contrat) =>
          surfaceTotale + (contrat?.surface_pressentie_sous_contrat || 0),
        0
      );
    return surfaceTotale.toFixed(4);
  }, [contratsProprietaireList]);

  const handleDeleteItem = async (itemId) => {
    dispatch(
      setFilteredItems({
        itemsList: contratsProprietaireList.filter(
          (contrat) => contrat.id !== itemId
        ),
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        field: 'delete',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label='Éditer'
            onClick={() =>
              navigate(
                `/fiche-contrat/${row.id}?propriete_id=${row.propriete.id}`
              )
            }
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label='Supprimer'
            onClick={() => handleDeleteItem(id)}
          />,
        ],
      },
      {
        field: 'code_chantier',
        headerName: 'Nom du contrat',
        width: 350,
        editable: false,
        valueGetter: (_, row) => row.code_chantier,
      },
      {
        field: 'avancement',
        headerName: 'Avancement',
        width: 250,
        editable: false,
        valueGetter: (_, row) => row.etat.nom,
      },
      {
        field: 'surface_sous_contrat',
        headerName: 'Surface sous contrat (ha)',
        width: 200,
        editable: false,
        valueGetter: (_, row) => row.surface_pressentie_sous_contrat,
      },
      {
        field: 'date_derniere_modif',
        headerName: 'Date de dernière modification',
        width: 200,
        editable: false,
        valueGetter: (_, row) =>
          fromISODateTime(row.last_modification_datetime),
      },
    ],
    [contratsProprietaireList, navigate, handleDeleteItem]
  );

  const openSaveDialog = async () => {
    if (
      await open({
        title: 'Redirection vers la fiche "Contrat"',
        content:
          'Voulez-vous sauvegarder les modifications en cours de la fiche propriété ?',
        confirmButton: 'Sauvegarder',
      })
    ) {
      onSubmit().then(
        (propriete_id) =>
          propriete_id &&
          navigate(`/fiche-contrat?propriete_id=${propriete_id}`)
      );
    }
  };

  return (
    <TabPanelForm currentValue={tabValue} index={6}>
      <form>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <Button
              onClick={openSaveDialog}
              variant='contained'
              disabled={disabledSubmit}
            >
              Créer un nouveau contrat
            </Button>
          </Grid>
          <Grid item xs={12}>
            <BaseDataGrid columns={columns} rows={contratsProprietaireList} />
          </Grid>
        </Grid>
        <Typography variant='body2'>
          Surface totale sous contrat (signé) : {surfaceTotale} ha
        </Typography>
      </form>
    </TabPanelForm>
  );
}

ContratsNeosylvaTab.propTypes = {
  tabValue: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  contratsProprietaireList: PropTypes.array,
  disabledSubmit: PropTypes.bool.isRequired,
};
