import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import DatePickerField from '@/components/controlled-fields/DatePickerField';

import DocumentFormDialog from '@/features/shared/document/DocumentFormDialog';

import { setFilteredItems } from '@/slices/itemSlice';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const DEFAULT_VALUES = {
  date_signature_acte_notarie: null,
};

export default function SignatureActeNotarieFormDialog({
  openState,
  setEtatContrat,
  itemState,
  documentsList,
}) {
  const [open, setOpen] = openState;
  const [openDocument, setOpenDocument] = useState(false);
  const [item, setItem] = itemState;
  const [document, setDocument] = useState(null);

  const userId = useSelector((store) => store.persistAuthReducer.user.id);
  const dispatch = useDispatch();

  const {
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    reValidateMode: 'onBlur',
    mode: 'onChange',
    values: item,
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle justifyContent='space-between'>
        <Grid container justifyContent='space-between'>
          <Grid item>{"Signature de signature de l'acte notarié"}</Grid>
          <Grid item>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Grid
          container
          sx={{ mt: 1 }}
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12} sm={6}>
            <Typography component='div' color='primary'>
              Date de signature
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePickerField
              control={control}
              required="Date de signature de l'acte notarié requise."
              name='date_signature_acte_notarie'
              label="Date de signature de l' acte notarié"
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 3 }} spacing={2} alignItems='center'>
          <Grid item>
            <Button onClick={() => setOpenDocument(true)} variant='contained'>
              joindre le contrat
            </Button>
          </Grid>
          {document && (
            <Grid item>
              <Typography component='div'>
                {document.repository
                  ? `${document.name} (${document.repository})`
                  : `${document.name} (${document.filename})`}
              </Typography>
            </Grid>
          )}
          <DocumentFormDialog
            open={openDocument}
            setOpen={setOpenDocument}
            onSubmit={(value) => {
              setDocument(value);
              setOpenDocument(false);
            }}
            enableUpload
            userId={userId}
          />
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2} alignItems='center'>
          <Grid item>
            <Typography component='div'>
              {
                "En validant, l'avancement du contrat passera automatiquement au stade « acte notarié signé ». En réinitialisant, vous dévalider la signature de l'acte notarié et faites disparaitre toutes les informations saisies."
              }
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={() => {
            setItem(DEFAULT_VALUES);
            setEtatContrat('SIGNE');
            setOpen(false);
          }}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => {
            setItem(getValues());
            setEtatContrat('NOTARIE_SIGNE');
            if (document) {
              dispatch(
                setFilteredItems({
                  itemsList: documentsList.concat([document]),
                  itemType: 'documents',
                })
              );
              setDocument(null);
            }
            setOpen(false);
          }}
          variant='contained'
          disabled={!isValid}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SignatureActeNotarieFormDialog.propTypes = {
  openState: PropTypes.array.isRequired,
  setEtatContrat: PropTypes.func.isRequired,
  itemState: PropTypes.array.isRequired,
  documentsList: PropTypes.array,
};
