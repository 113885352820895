import React, { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';

import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SelectField from '@/components/controlled-fields/SelectField';
import DividerTitle from '@/components/DividerTitle';
import TabPanelForm from '@/components/TabPanelForm';

import DocumentTable from '@/features/shared/document/DocumentTable';
import SignatureContrat from '@/features/fiche-contrat/signature-contrat/SignatureContrat';

import SignatureActeNotarie from './signature-acte-notarie/SignatureActeNotarie';
import Rupture from './rupture/Rupture';

import { fromISODate } from '@/utils/date';

export default function SuiviAvancementTab({
  tabValue,
  form,
  choices,
  onSubmit,
  disabledSubmit,
  contratId,
  documentsList,
  contratProprietaire,
  codesChantierList,
  signatureSeingPriveState,
  signatureActeNotarieState,
  motifRuptureState,
  unitesGestionList,
}) {
  const navigate = useNavigate();
  const { control, setValue } = form;

  const setEtatContrat = useCallback(
    (code) => {
      setValue(
        'contrat_proprietaire.etat',
        choices.etats_contrat.find((etat) => etat.code === code),
        { shouldValidate: true }
      );
    },
    [choices, setValue]
  );

  const [
    motifRuptureWatch,
    dateSignatureActeNotarieWatch,
    dateSignatureSeingPriveWatch,
  ] = useWatch({
    control,
    name: [
      'contrat_proprietaire.motif_rupture',
      'contrat_proprietaire.date_signature_acte_notarie',
      'contrat_proprietaire.date_signature_contrat_seing_prive',
    ],
  });

  useEffect(() => {
    if (!choices || !contratId) {
      return;
    }
    setEtatContrat('CONTACTE');
  }, [choices, contratId, setEtatContrat]);

  return (
    <TabPanelForm currentValue={tabValue} index={1}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <SelectField
              control={control}
              name='contrat_proprietaire.etat'
              label='Avancement'
              values={choices?.etats_contrat}
              itemMapper={(item) => ({
                key: item.code,
                label: item.nom,
              })}
              renderValue={(value) => value.nom}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Diagnostics' />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              onClick={() => {
                onSubmit().then((contratId) =>
                  navigate(
                    `/modifier-diagnostic-neosylva?contrat_id=${contratId}`
                  )
                );
              }}
              variant='contained'
              disabled={disabledSubmit}
              fullWidth
            >
              CR de visite CMF
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              onClick={() => {
                onSubmit().then((contratId) =>
                  navigate(`/diagnostic-gestionnaire?contrat_id=${contratId}`)
                );
              }}
              variant='contained'
              disabled={disabledSubmit}
              fullWidth
            >
              Diagnostic Gestionnaire
            </Button>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Finalisation contrat' />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <SignatureContrat
              setEtatContrat={setEtatContrat}
              codesChantierList={codesChantierList}
              unitesGestionList={unitesGestionList}
              itemState={signatureSeingPriveState}
              documentsList={documentsList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{fromISODate(dateSignatureSeingPriveWatch)}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <SignatureActeNotarie
              setEtatContrat={setEtatContrat}
              itemState={signatureActeNotarieState}
              documentsList={documentsList}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              {fromISODate(dateSignatureActeNotarieWatch)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6} md={4}>
            <Rupture
              setEtatContrat={setEtatContrat}
              itemState={motifRuptureState}
              choices={choices}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{motifRuptureWatch?.nom}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12}>
            <DividerTitle text='Documents' />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center' sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <DocumentTable
              items={documentsList}
              title={'Documents'}
              contratProprietaire={contratProprietaire}
              enableUpload
            />
          </Grid>
        </Grid>
      </form>
    </TabPanelForm>
  );
}

SuiviAvancementTab.propTypes = {
  tabValue: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  choices: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
  contratId: PropTypes.string,
  documentsList: PropTypes.array,
  contratProprietaire: PropTypes.object,
  codesChantierList: PropTypes.array.isRequired,
  signatureSeingPriveState: PropTypes.array.isRequired,
  signatureActeNotarieState: PropTypes.array.isRequired,
  motifRuptureState: PropTypes.array.isRequired,
  unitesGestionList: PropTypes.array,
};
