import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useChoices from '@/hooks/useChoices';

import BaseTextField from '@/components/controlled-fields/text-fields/BaseTextField';
import CodePostalTextField from '@/components/controlled-fields/text-fields/CodePostalTextField';

import AdresseField from '@/features/shared/AdresseField';
import DepartementField from '@/features/shared/DepartementField';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ContactAutocompleteSearchField from '@/features/shared/ContactAutocompleteSearchField';

const EXISTING_CONTACT_MODE = 0;
const NEW_CONTACT_MODE = 1;

export default function ReferentTechniqueFormDialog({ item, onSubmit }) {
  const choicesContact = useChoices('contacts');

  const isEdition = useMemo(() => !!item?.id, [item]);

  const [isOpen, setIsOpen] = useState(false);
  const [referent, setReferent] = useState(item);
  const [mode, setMode] = useState(null);

  const handleClose = () => setIsOpen(false);

  const defaultValues = item || {
    nom: null,
    prenom: null,
    telephone_fixe: null,
    telephone_portable: null,
    email: null,
    adresse: null,
    commune: null,
    code_postal: null,
    departement: null,
  };
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues,
    values: referent,
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  // Reset form & proprietaire on mode switch (only when creating a new proprietaire)
  useEffect(() => {
    if (isEdition) {
      return;
    }
    reset(defaultValues);
    setReferent(null);
  }, [mode]);

  useEffect(() => {
    setMode(isEdition ? EXISTING_CONTACT_MODE : NEW_CONTACT_MODE);
    isEdition && setReferent(item);
  }, [isEdition]);

  const getReferentLabel = (item) => {
    return item?.prenom ? `${item.prenom} ${item.nom}` : item.nom;
  };

  return (
    <>
      {item ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Button
            variant='outlined'
            fullWidth
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant='body1' style={{ marginRight: '8px' }}>
              {getReferentLabel(item)}
            </Typography>
            <div>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setReferent(item);
                  setIsOpen(true);
                }}
                style={{ cursor: 'pointer', marginRight: '8px' }}
              >
                <EditIcon />
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setReferent(null);
                  onSubmit(null);
                }}
                style={{ cursor: 'pointer' }}
              >
                <ClearIcon />
              </span>
            </div>
          </Button>
        </div>
      ) : (
        <Button onClick={() => setIsOpen(true)} variant='contained' fullWidth>
          Choix du référent technique contractuel
        </Button>
      )}

      <Dialog open={isOpen} onClose={handleClose} fullScreen>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Néosylva
            </Typography>
            <Button
              autoFocus
              color='inherit'
              disabled={
                !isValid || (mode === EXISTING_CONTACT_MODE && !referent)
              }
              onClick={() => {
                handleSubmit(onSubmit)();
                handleClose();
              }}
            >
              {item ? 'Éditer' : 'Créer'}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogTitle>Référent technique</DialogTitle>
        <DialogContent>
          <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <ToggleButtonGroup
                disabled={isEdition}
                color='primary'
                value={mode}
                exclusive
                onChange={(_, newMode) => setMode(newMode)}
              >
                <ToggleButton value={NEW_CONTACT_MODE}>
                  Nouveau contact
                </ToggleButton>
                <ToggleButton value={EXISTING_CONTACT_MODE}>
                  Contact existant
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {mode === EXISTING_CONTACT_MODE && (
              <Grid item xs={12} md={5}>
                <ContactAutocompleteSearchField
                  disabled={isEdition}
                  contact={referent}
                  setContact={setReferent}
                  required
                />
              </Grid>
            )}
          </Grid>
          {(mode === NEW_CONTACT_MODE ||
            (mode === EXISTING_CONTACT_MODE && referent)) && (
            <>
              <Grid container sx={{ p: 2 }} spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4} md={3}>
                  <BaseTextField
                    control={control}
                    name='nom'
                    label='Nom'
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <BaseTextField
                    control={control}
                    name='prenom'
                    label='Prénom'
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <BaseTextField
                    control={control}
                    name='telephone_fixe'
                    label='Téléphone fixe'
                    maxLength={16}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <BaseTextField
                    control={control}
                    name='telephone_portable'
                    label='Téléphone portable'
                    maxLength={16}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <BaseTextField
                    control={control}
                    name='email'
                    label='E-mail'
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <AdresseField
                    control={control}
                    fieldName='adresse'
                    label='Adresse'
                    setValue={setValue}
                    relatedFiedlNames={{
                      commune: 'commune',
                      codePostal: 'code_postal',
                      pays: 'pays',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <BaseTextField
                    control={control}
                    name='commune'
                    maxLength={256}
                    label='Commune'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CodePostalTextField
                    control={control}
                    name='code_postal'
                    label='Code postal'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DepartementField
                    label='Département'
                    departements={choicesContact?.departements}
                    control={control}
                    departementFieldName='departement'
                    codePostalFieldName='code_postal'
                    setValue={setValue}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

ReferentTechniqueFormDialog.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
