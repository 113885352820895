import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function ProgrammesRd() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'programmes_rd';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/programme-rd/${itemId}`);
  };

  const handleDeleteItem = async (itemId, programmeName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de le programme ${programmeName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom)}
        />,
      ],
    },
    {
      field: 'nom',
      headerName: 'Nom',
      width: 120,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom} />,
    },
    {
      field: 'peuplements_objectifs_essences_list',
      headerName: 'Essences de parcelles',
      width: 260,
      valueGetter: (value) =>
        value?.map(
          (essence_parcelle) =>
            `${essence_parcelle.type.nom} - ${essence_parcelle.essence.nom_vernaculaire}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.peuplements_objectifs_essences_list
              ? row.peuplements_objectifs_essences_list
              : [],
            getRelatedName: (essence_parcelle) =>
              `${essence_parcelle.type.nom} - ${essence_parcelle.essence.nom_vernaculaire}`,
            relatedPath: '/essence-parcelle',
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/programme-rd');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Programmes R&D
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter un programme R&D
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
