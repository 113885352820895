import React from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CellExpand from '@/components/CellExpand';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';
import XLSXExportButton from '@/components/XLSXExportButton';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function Entreprises() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'entreprises';

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/entreprise/${itemId}`);
  };

  const handleDeleteItem = async (itemId, itemName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de ${itemName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom)}
        />,
      ],
    },
    {
      field: 'nom',
      headerName: 'Nom',
      width: 200,
      editable: false,
      renderCell: ({ row }) => <CellExpand value={row.nom} />,
    },
    {
      field: 'adresse',
      headerName: 'Adresse',
      width: 240,
      editable: false,
    },
    {
      field: 'commune',
      headerName: 'Commune',
      width: 140,
      editable: false,
    },
    {
      field: 'code_postal',
      headerName: 'Code postal',
      width: 110,
      editable: false,
    },
    {
      field: 'telephone',
      headerName: 'Téléphone',
      width: 120,
      editable: false,
    },
    {
      field: 'siret',
      headerName: 'SIRET',
      width: 80,
      editable: false,
    },
    {
      field: 'relation_contractuelle_neosylva',
      headerName: 'Relation contractuelle avec Néosylva',
      type: 'boolean',
      width: 120,
      editable: false,
    },
    {
      field: 'emploi_insertion',
      headerName: 'Emploi insertion',
      type: 'boolean',
      width: 120,
      editable: false,
    },
    {
      field: 'fonction',
      headerName: 'Fonction',
      width: 140,
      editable: false,
    },
    {
      field: 'contacts_list',
      headerName: 'Contacts',
      width: 260,
      valueGetter: (value) => value.map((contact) => contact.nom),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.contacts_list,
            getRelatedName: (contact) => contact.nom,
            relatedPath: '/contact',
            relatedVerboseName: 'contact',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/entreprise');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Entreprises
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une entreprise
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <XLSXExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
