import React from 'react';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import useChoices from '@/hooks/useChoices';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import useItems from '@/hooks/useItems';

import CSVExportButton from '@/components/CSVExportButton';
import StatefulDataGrid from '@/components/StatefulDataGrid';
import ManyItemsCell from '@/components/ManyItemsCell';

import { deleteItem } from '@/slices/itemSlice';

import { fromISODate, fromISODateTime } from '@/utils/date';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridActionsCellItem } from '@mui/x-data-grid';

export default function UnitesGestion() {
  const { open } = useConfirmationDialog();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const itemType = 'unites_gestion';

  const choices = useChoices(itemType);

  const items = useItems(itemType);

  const handleEditItem = (itemId) => {
    navigate(`/unite-gestion/${itemId}`);
  };

  const handleDeleteItem = async (itemId, parcelleName) => {
    const isConfirmed = await open({
      title: 'Suppression',
      content: `Confirmer la suppression de l'unité de gestion ${parcelleName} ?`,
    });

    if (isConfirmed) {
      dispatch(deleteItem({ itemId, itemType }));
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<EditIcon />}
          label='Éditer'
          onClick={() => handleEditItem(params.id)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label='Supprimer'
          onClick={() => handleDeleteItem(params.id, params.row.nom)}
        />,
      ],
    },
    {
      field: 'nom',
      headerName: 'Nom',
      width: 120,
      editable: false,
    },
    {
      field: 'propriete',
      headerName: 'Propriété',
      width: 260,
      valueGetter: (value) => value.nom_commun, // For filtering ...
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/propriete/${row.propriete.id}`}>
          {row.propriete.nom_commun}
        </Link>
      ),
    },
    {
      field: 'station',
      headerName: 'Station',
      width: 160,
      editable: false,
      valueGetter: (value) => value?.numero,
      renderCell: ({ row }) => 
        row.station && (
          <Link component={RouterLink} to={`/station/${row.station?.id}`}>
            {row.station?.numero}
          </Link>
        ),
    },
    {
      field: 'peuplement_objectif',
      headerName: 'Peuplement objectif',
      type: 'singleSelect',
      valueOptions: choices?.peuplements_objectifs_types.map((value) => value.nom),
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'type_traitement',
      headerName: 'Type de traitement',
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'foret_semi_naturelle',
      headerName: 'Foret semi naturelle',
      type: 'singleSelect',
      valueOptions: choices?.forets_semi_naturelles.map((value) => value.nom),
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'densite',
      headerName: 'Densité',
      width: 160,
      editable: false,
    },
    {
      field: 'itineraire_sylvicole_preconise',
      headerName: 'Itinéraire sylvicole préconisé',
      type: 'singleSelect',
      valueOptions: choices?.itineraires_sylvicoles_preconises.map((value) => value.nom),
      width: 220,
      editable: false,
      valueGetter: (value) => value?.nom,
    },
    {
      field: 'observations_peuplement',
      headerName: 'Observations',
      width: 240,
      editable: false,
    },
    {
      field: 'eligibilite_carbone',
      headerName: 'Éligibilité carbone',
      width: 160,
      type: 'boolean',
      editable: false,
    },
    {
      field: 'surface_sig_ug',
      headerName: 'Surface unité de gestion (ha)',
      width: 160,
      editable: false,
      valueFormatter: (value) => (value ? value.toFixed(4) : null),
    },
    {
      field: 'peuplement_initial',
      headerName: 'Peuplement initial',
      width: 260,
      valueGetter: (value) => value?.type.nom, // For filtering ...
      renderCell: ({ row }) =>
        row.peuplement_initial && (
          <Link
            component={RouterLink}
            to={`/peuplement-initial/${row.peuplement_initial.id}`}
          >
            {row.peuplement_initial.type.nom}
          </Link>
        ),
    },
    {
      field: 'interventions_list',
      headerName: 'Interventions',
      width: 260,
      valueGetter: (value) =>
        value.map(
          (intervention) =>
            `${intervention.type.code} - ${intervention.annee_prevue}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.interventions_list,
            getRelatedName: (intervention) =>
              `${intervention.type.code} - ${intervention.annee_prevue}`,
            relatedPath: '/intervention',
            relatedVerboseName: 'intervention',
          }}
        />
      ),
    },
    {
      field: 'services_env_list',
      headerName: 'Services environnementaux',
      width: 260,
      valueGetter: (value) =>
        value.map(
          (service_env) =>
            `${service_env.recette} - ${fromISODate(service_env.date_paiement)}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.services_env_list,
            getRelatedName: (service_env) =>
              `${service_env.recette} - ${fromISODate(
                service_env.date_paiement
              )}`,
            relatedPath: '/service-env',
            relatedVerboseName: 'service',
          }}
        />
      ),
    },
    {
      field: 'stock_carbone',
      headerName: 'Stock carbone',
      width: 260,
      valueGetter: (value) => value?.nom_projet, // For filtering ...
      renderCell: ({ row }) =>
        row.stock_carbone && (
          <Link component={RouterLink} to={`/propriete/${row.propriete.id}`}>
            {row.propriete.nom_commun}
          </Link>
        ),
    },
    {
      field: 'essences_list',
      headerName: 'Essence',
      width: 260,
      valueGetter: (value) =>
        value.map(
          (essence_parcelle) =>
            `${essence_parcelle.type.nom} - ${essence_parcelle.essence.nom_vernaculaire}`
        ),
      renderCell: ({ row }) => (
        <ManyItemsCell
          itemId={row.id}
          relatedItems={{
            relatedItemsList: row.essences_list,
            getRelatedName: (essence_parcelle) =>
              `${essence_parcelle.type.nom} - ${essence_parcelle.essence.nom_vernaculaire}`,
            relatedPath: '/essence-parcelle',
            relatedVerboseName: 'essence',
          }}
        />
      ),
    },
    {
      field: 'creation_datetime',
      headerName: 'Création',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
    {
      field: 'last_modification_datetime',
      headerName: 'Dernière modification',
      width: 220,
      editable: false,
      valueFormatter: (value) => fromISODateTime(value),
    },
  ];

  const handleFormRedirect = () => {
    navigate('/unite-gestion');
  };

  return (
    <Box component='main' mx={3} mt={2}>
      <Typography mb={1} variant='h5' component='div'>
        Unités de gestion
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} sm='auto'>
          <Button variant='contained' onClick={handleFormRedirect} fullWidth>
            Ajouter une unité de gestion
          </Button>
        </Grid>
        <Grid item xs={12} sm='auto'>
          <CSVExportButton itemType={itemType} fullWidth />
        </Grid>
      </Grid>
      <StatefulDataGrid columns={columns} rows={items} boxSx={{ my: 2 }} />
    </Box>
  );
}
