import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import SelectField from '@/components/controlled-fields/SelectField';

const DEFAULT_VALUES = {
  motif_rupture: null,
};

export default function RuptureFormDialog({
  openState,
  setEtatContrat,
  itemState,
  choices,
}) {
  const [open, setOpen] = openState;
  const [item, setItem] = itemState;

  const {
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    reValidateMode: 'onBlur',
    mode: 'onChange',
    values: item,
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle justifyContent='space-between'>
        <Grid container justifyContent='space-between'>
          <Grid item>Rupture du contrat</Grid>
          <Grid item>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Grid
          container
          sx={{ mt: 1 }}
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item xs={12}>
            <SelectField
              control={control}
              name='motif_rupture'
              label='Motif de rupture du contrat'
              values={choices?.motifs_rupture}
              itemMapper={(item) => ({
                key: item.code,
                label: item.nom,
              })}
              renderValue={(value) => value.nom}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2} alignItems='center'>
          <Grid item>
            <Typography component='div'>
              En validant, vous confirmer que le contrat a bien été rompu et le
              statut du contrat changera en “Contrat rompu”.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={() => {
            setItem(DEFAULT_VALUES);
            setOpen(false);
          }}
        >
          Réinitialiser
        </Button>
        <Button
          onClick={() => {
            setItem(getValues());
            setEtatContrat('ROMPU');
            setOpen(false);
          }}
          variant='contained'
          disabled={!isValid}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RuptureFormDialog.propTypes = {
  openState: PropTypes.array.isRequired,
  setEtatContrat: PropTypes.func.isRequired,
  itemState: PropTypes.array.isRequired,
  choices: PropTypes.object,
};
